.product-card {
    padding: 0.5rem;
    border: 0.1rem solid $light;
    box-shadow: 0 0 10px $light;
    border-radius: 1rem;

    img {
        height:250px;
        object-fit: cover;
        border-radius: 3px 3px 0px 0px;
    }
    .card-body {
        border-top: 1px solid var(--bs-gray-300);
    }

    .price {
        font-weight: bold;
    }

    .old-price {
        color: $gray-600 !important;
        font-weight: normal;
        height: 1.5em;
    }
}
