@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100;400;700&family=Noto+Sans:wght@400;700&display=swap');
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";


$light: #e2e5e6;
$white: #ffffff;
$black: #050505;
$dark: #631F88;
$primary: #71239b;
$secondary: #F7E06C;
$success: #10995f;
$danger: #e62b07;
$warning: #daca12;
$info: #fa364a;

$custom-colors: (
    "light": $light,
    "white": $white,
    "black": $black,
    "dark": $dark,
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "danger": $danger,
    "warning": $warning,
    "info": $info,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

$box-shadow: 0 .5rem 1rem rgba($black, .1);

$utilities: map-merge(
        $utilities, (
        "width": map-merge(
                map-get($utilities, "width"),
                (
                    values: (
                        5: 5%,
                        10: 10%,
                        15: 15%,
                        20: 20%,
                        25: 25%,
                        30: 30%,
                        35: 35%,
                        40: 40%,
                        45: 45%,
                        50: 50%,
                        55: 55%,
                        60: 60%,
                        65: 65%,
                        70: 70%,
                        75: 75%,
                        80: 80%,
                        85: 85%,
                        90: 90%,
                        95: 95%,
                        100: 100%,
                    ),
                ),
        ),
    )
);

$link-color: $primary;
$font-family-base: 'Noto Sans Georgian', 'Noto Sans', sans-serif;
$input-focus-box-shadow: none !important;
$btn-focus-box-shadow: none !important;
$btn-active-box-shadow: none !important;
$form-select-focus-box-shadow: none !important;
$pagination-focus-box-shadow: none !important;
$input-btn-focus-box-shadow: none !important;
@import "~bootstrap/scss/bootstrap";
@import '~bootstrap-icons/font/bootstrap-icons';

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

footer {
    background: #EBF2EA;
    margin-top: auto;
}

@import "ecommerce/product";

#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .site-loading {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 5050;
        background: white;
        opacity: 0;
        transition: all .5s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        font-weight: bold;
    }

    .site-loading.active {
        opacity: 1;
    }
}

#header {
    background: #EBF2EA;

    .site-logo {
        height: 48px;
        @media (min-width: 768px) {
            height: 64px;
        }
    }
}

.messenger{
    position: fixed;
    bottom: 24px;
    right: 12px;
    width: 48px;
    height: 48px;
    img {
        width: 100%;
        height: 100%;
    }
}

input[type=checkbox] {
    transform: scale(1.2);
    border-color: $primary;
}

input[type=checkbox]:hover {
    cursor:pointer;
}

.login-remember-checkbox, .register-terms-and-condition-checkbox {
    margin-left: 3px !important;
}

.registration-category {
    background-color: #EBF2EA;
    border-radius: 13px;
    .active {
        background-color: $primary;
        border-radius:13px;
        color: $white
    }
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    border-left: 1.5px solid #dee2e6 !important;
    padding-left: 0.5rem;
    height:100%;
}

.password-toggle-icon i {
    font-size: 18px;
    line-height: 39px;
    transition: color 0.3s ease-in-out;
    margin-bottom: 20px;
}

.test-server {
    background: rgba(255, 0, 0, .5);
    position: fixed;
    z-index: 2000;
    padding: .25rem;
    border-bottom-right-radius: 1rem;
    color: #000000;
    font-weight: 600;
    font-size: 1rem;
}

